import L from "leaflet";
import { stripTime, colon } from "../components/routing/filters";
import { trackFeatureUsage } from "../ga";
import ADD_VUE_CONTAINER from "../popupManager";

export const mapClicked = ({ state, commit }, evt) => {
  // to load the UI components that, on their part, load the bootstrap CSS
  ADD_VUE_CONTAINER();

  // this id is used to identify the popup element in question.
  // this is needed because when a second or third popup open event
  // happens, the garbage collector might not have had time to remove
  // the previous popup from the dom. this, in turn, could result
  // in adding event listeners to the previous dom elements.
  const tsId = `p-cont-${new Date().getTime()}`;

  const pContent = `
  <div id="${tsId}" style="padding:0.05rem;text-align:center;">
  Reitti:<br>
    <button id="route-from" class="btn btn-primary btn-sm"
            style="padding:0.05rem 0.20rem;">Täältä</button>
    <button id="route-to" class="btn btn-primary btn-sm"
            style="padding:0.05rem 0.20rem;">Tänne</button>
  </div>
  `;

  // ADD_VUE_CONTAINER adds bootstrap styles that are used in the popup
  // they might not be available at DOM insertion time which would give the
  // popup container a too narrow size --> this ensures that buttons fit nicely
  L.popup({ minWidth: 110, className: "routing-popup" })
    .setLatLng(evt.latlng)
    .setContent(pContent)
    // .on('add', () => { console.log('ADDED'); })
    // .on('remove', () => { console.log('REMOVED'); })
    .openOn(state.mappi);

  const handleClick = (type, latlng) => {
    // this is needed so that every component gets notified about
    // new clicks even if they don't change anything (clicking on same popup)
    state.options.routingByClick.type = undefined;
    state.options.routingByClick.latlng = undefined;
    state.options.routingByClick.type = type;
    state.options.routingByClick.latlng = `${latlng.lng},${latlng.lat}`;
    commit("setMobileView", "routing");
    commit("toggleMobile", true);

    trackFeatureUsage("route-from-map");
  };

  // event listeners will be removed by the garbage collector
  // once the popup is closed
  document
    .querySelector(`#${tsId} #route-from`)
    .addEventListener("click", () => {
      handleClick("from", evt.latlng);
    });
  document.querySelector(`#${tsId} #route-to`).addEventListener("click", () => {
    handleClick("to", evt.latlng);
  });
};

export const showRouteOnMap = ({ state, commit }, obj) => {
  console.log("showRouteOnMap");

  // this ensures that Vue components alongside the CSS are loaded
  // and the global "showing route on map" info box will be visible
  ADD_VUE_CONTAINER();

  commit("routeOnMap", obj);

  if (state.mobileUser) {
    commit("toggleMobile", false);
  }

  if (!state.mappi.hasLayer(state.routeLayer)) {
    state.mappi.addLayer(state.routeLayer);
  }

  const { legs } = obj;

  legs.forEach((leg) => {
    const { type } = leg;
    const lineColor = type === "walk" ? "blue" : "green";

    const latlngs = leg.shape.map((el) => [el.y, el.x]);

    L.polyline(latlngs, { color: lineColor }).addTo(state.routeLayer);
    const f = leg.locs[0];
    const l = leg.locs[leg.locs.length - 1];

    const cont = (loc) => {
      let start = '<span class="route-text">';
      if ("shortCode" in loc) {
        start += `${loc.name} (${loc.shortCode})<br>`;
      }
      start += `${colon(stripTime(loc.arrTime))}/${colon(
        stripTime(loc.depTime)
      )}</span>`;
      return start;
    };

    [f, l].forEach((loc) => {
      const pop = L.popup({
        className: "routing-popup",
        minWidth: 100,
        maxWidth: "auto",
        closeButton: false,
        autoPan: false,
        closeOnClick: false,
      })
        .setLatLng([loc.coord.y, loc.coord.x])
        .setContent(cont(loc));

      state.routeLayer.addLayer(pop);
    });
  });
};
