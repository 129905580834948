import { trackPanelView } from "../ga";
import { pad } from "../utils";
import ADD_VUE_CONTAINER from "../popupManager";

export const controlClicked = ({ commit }, control) => {
  // järjestys pitää olla
  // 1. setMobileView
  // 2. toggleMobile
  // muuten aukeava valikko ei näytä komponenttia

  commit("setMobileView", control);
  commit("toggleMobile", true);
  trackPanelView(control);
};

export const checkUpdates = ({ state, commit }) => {
  const LATEST = window.UPDATES_LATEST_MSG_ID;
  if (state.prefs.updates.seenMsgs.indexOf(LATEST) === -1) {
    // vanha käyttäjä -> näytetään
    if (state.prefs.updates.visitCount > 1) {
      setTimeout(() => {
        state.ROUTER.showUpdates = true;
        ADD_VUE_CONTAINER("updates");
      }, 2500);
    } else {
      // uusi käyttäjä -> ei näytetä ollenkaan
      commit("prefsMsgHidden", LATEST);
    }
  }
};

export const checkGlobalInfoMsg = ({ state }) => {
  setTimeout(() => {
    const base =
      window.location.hostname === "localhost"
        ? "http://localhost:8080"
        : "https://nyssetutka.fi";
    fetch(`${base}/api/current_info_message?ts=${new Date().getTime()}`)
      .then((res) => res.json())
      .then((msgData) => {
        const now = new Date();
        const compareDate = `${now.getFullYear()}-${pad(
          now.getMonth() + 1
        )}-${pad(now.getDate())}`;
        if (
          compareDate >= msgData.show_start &&
          compareDate <= msgData.show_end
        ) {
          state.ROUTER.showGlobalInfoMsg = true;
          state.messages.globalInfoMsg = msgData.text;
          ADD_VUE_CONTAINER();
        }
      });
  }, 1000);
};
