import { TrackJS } from "trackjs";
import store from "./store/index";
import { BUNDLEMODE } from "./globalFlags";

let skipFirstControllerChange = false;
let failureCount = 0;
export default () => {
  if (BUNDLEMODE !== "MODERN") {
    console.log("skipping SW; bundle === LEGACY");
    return;
  }

  console.log("initSW");
  if (!("serviceWorker" in navigator)) {
    console.log("initSW::cancelled -> not in navig");
    return;
  }

  if (typeof navigator.serviceWorker !== "undefined") {
    console.log("SW:: starting register");
    navigator.serviceWorker.register("/service-worker.js").then(() => {
      console.log("SW:: registered");
    });

    if (!navigator.serviceWorker.controller) {
      skipFirstControllerChange = true;
    }

    navigator.serviceWorker.addEventListener("controllerchange", () => {
      if (skipFirstControllerChange) {
        console.log("SW:: controllerChange -> initial reg -> skipping...");
      } else {
        console.log("SW:: controllerChange!!!!!!!!!!!!!");
        store.commit("updatesFound");
      }
    });

    const checkUpdates = () => {
      if (document.visibilityState === "visible") {
        console.log("SW:: checkUpdates");
        navigator.serviceWorker
          .getRegistration()
          .then((reg) => {
            if (reg) {
              reg.update();
            }
          })
          .catch((err) => {
            console.log(`SW:: update() failed ; count ${failureCount}`);
            failureCount += 1;
            console.log(err);
            if (failureCount > 10) {
              TrackJS.track(err);
            }
          });

        setTimeout(checkUpdates, 90000);
      }
    };

    setTimeout(checkUpdates, 60000);

    window.addEventListener("visibilitychange", () => {
      if (document.visibilityState === "visible") {
        console.log("SW:: VIS CHANGE -> scheduling update check");
        setTimeout(checkUpdates, 5000);
      }
    });
  }
};
