import { TrackJS } from "trackjs";

import { msgShown } from "./ga";

let visible = false;

export function showNetworkError() {
  if (visible) {
    return;
  }
  console.log("showNetworkError");
  const elem = document.getElementById("error-message");
  elem.style.display = "block";
  elem.innerText =
    "Verkkoyhtedessä vaikuttaa olevan häiriötä... Osa tiedoista ei ole ajan tasalla";
  visible = true;

  msgShown();
  TrackJS.addMetadata("msg-shown", `${new Date()} api-err`);
  TrackJS.track("showNetworkError()");
}

export function hideNetworkError() {
  console.log("hideNetworkError");
  const elem = document.getElementById("error-message");
  elem.style.display = "none";
  visible = false;
  TrackJS.addMetadata("msg-hidden", `${new Date()} api-err`);
}
