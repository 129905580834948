import debounce from "lodash/debounce";
import { trackFeatureUsage } from "./ga";

export const pad = (num) => (num < 10 ? `0${num}` : `${num}`);

export function initFonts() {
  if (!("fonts" in document)) {
    console.log("document.fonts NOT supported");
    return;
  }

  const signikaFace = new FontFace(
    "Signika",
    `url(${require("./assets/signika.woff2")})`,
    {
      style: "normal",
      weight: "400",
    }
  );

  signikaFace.load();
  signikaFace.loaded.then(() => {
    console.log("signikaFace loaded");
  });
  document.fonts.add(signikaFace);

  document.fonts.ready.then(() => {
    console.log("document.fonts.ready");
    document.body.classList += "fonts-loaded";
  });
}

export function iosFix() {
  const html = document.querySelector("html");

  function onResize() {
    console.log("iosFix::_onResize::fired");
    html.style.height = `${window.innerHeight}px`;
  }

  window.addEventListener(
    "resize",
    debounce(onResize, 125, {
      leading: true,
      maxWait: 250,
      trailing: true,
    })
  );

  onResize();
}

export function fbUserAgentNotification() {
  // if (!(/fbtest/.test(window.location.href))) {
  // return;
  // }
  const f = () => {
    const ua =
      window.navigator.userAgent || window.navigator.vendor || window.opera;
    const bool = /FBAV|FBAN/.test(ua);
    // console.log(`FBAV${bool}`);

    if (bool) {
      // jos ei ole arvoa, asetetaan yksi ja poistutaan
      // jos on arvo ja se on yksi, näytetään ilmoitus
      // jos ilmoituksen nappia klikataan, asetetaan 2
      // jos arvo on 2, ei tehdä mitään

      const gettu = window.localStorage.getItem("FBNOTIF");
      if (!gettu) {
        window.localStorage.setItem("FBNOTIF", "1");
        trackFeatureUsage("FBAV:1");
        return;
      }
      if (gettu === "2") {
        trackFeatureUsage("FBAV:2");
        return;
      }

      const d = document.getElementById("banneri-fb");
      d.style.display = "block";

      const b = document.createElement("button");
      b.classList = "btn btn-warning btn-sm";
      b.innerText = "Piilota viesti";

      b.addEventListener("click", () => {
        console.log("FBAV: clicked");
        window.localStorage.setItem("FBNOTIF", "2");
        document.getElementById("banneri-fb").style.display = "none";
        trackFeatureUsage("FBAV:click");
      });

      d.appendChild(b);
    }
  };

  setTimeout(f, 5500);
}

export function stripLetters(c) {
  return c.replace(/\D/g, "");
}

export function favoriteBus(state, lineCode) {
  const compCode = stripLetters(lineCode);

  if (state.prefs.fav.busses.length === 0) {
    return true;
  }
  if (state.prefs.fav.busses.length === state.live.BUSSES_ALL.length) {
    return false;
  }
  return state.prefs.fav.busses.indexOf(compCode) !== -1;
}

export const longPress = {
  bind(el, binding, vNode) {
    // Make sure expression provided is a function
    if (typeof binding.value !== "function") {
      // Fetch name of component
      const compName = vNode.context.name;
      // pass warning to console
      let warn = `[longpress:] provided expression '${binding.expression}' is not a function, but has to be`;
      if (compName) {
        warn += `Found in component '${compName}' `;
      }

      console.warn(warn);
    }

    // Define variable
    let pressTimer = null;

    // Define funtion handlers
    // Create timeout ( run function after 1s )
    const start = (e) => {
      if (e.type === "click" && e.button !== 0) {
        return;
      }

      if (pressTimer === null) {
        pressTimer = setTimeout(() => {
          // Run function
          handler();
        }, 400);
      }
    };

    // Cancel Timeout
    const cancel = () => {
      // Check if timer has a value or not
      if (pressTimer !== null) {
        clearTimeout(pressTimer);
        pressTimer = null;
      }
    };
    // Run Function
    const handler = (e) => {
      binding.value(e);
    };

    // Add Event listeners
    el.addEventListener("mousedown", start);
    el.addEventListener("touchstart", start);
    // Cancel timeouts if this events happen
    el.addEventListener("click", cancel);
    el.addEventListener("mouseout", cancel);
    el.addEventListener("touchend", cancel);
    el.addEventListener("touchcancel", cancel);
  },
};

export const secondsToString = (s) => {
  const minutes = Math.floor(s / 60);
  const hours = Math.floor(minutes / 60);
  const extra = minutes - hours * 60;
  return `${pad(hours)}:${pad(extra)}`;
};

export const mapWalttiDepartures = (deps) =>
  !deps
    ? []
    : deps
        .map((wp) => ({
          ...wp,
          monitored: wp.realtime,
          time: secondsToString(wp.realtimeDeparture),
          code: wp.trip.routeShortName,
          name: wp.headsign,
          id: wp.trip.id,
        }))
        .filter((dep) => dep.pickupType !== "NONE");

export const dateToFinnishString = (date) =>
  ["su", "ma", "ti", "ke", "to", "pe", "la"][date.getDay()];

export const dateToDateStr = (date) =>
  `${date.getFullYear()}${pad(date.getMonth() + 1)}${pad(date.getDate())}`;

export const dateStrToDate = (str) =>
  new Date(
    parseInt(str.slice(0, 4)),
    parseInt(str.slice(4, 6)) - 1,
    parseInt(str.slice(6))
  );
