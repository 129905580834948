import { fetchBlob } from "../fetch";
import { updateBussesOnMap, removeGhosts } from "./busActions";
import { updateStopsOnMap } from "./stopActions";
import ADD_VUE_CONTAINER from "../popupManager";
import { trackObjClick, gaMark } from "../ga";
import { disableBusses } from "../globalFlags";

let FORCE_BUS_LOC_UPDATE = false;
export { FORCE_BUS_LOC_UPDATE };

export default (store) => {
  console.log("mapPlugin.js");

  store.state.mappi.on("contextmenu", (evt) => {
    store.dispatch("mapClicked", evt);
  });

  FORCE_BUS_LOC_UPDATE = false;
  let ZOOMING = false;
  let MOVING = false;

  function touchMap() {
    if (disableBusses) {
      return;
    }

    if (document.hidden) {
      console.log("hidden...");
      return;
    }

    if (ZOOMING === false && MOVING === false) {
      updateBussesOnMap(store.state.live.latestBlob.lines);
      store.commit("commitBlobTS", store.state.live.latestBlob.ts);
    }
  }

  function stopRoutine() {
    if (!store.state.prefs.showStops) {
      return;
    }

    const shouldHave = store.state.mappi.getZoom() > 14;
    if (shouldHave) {
      requestAnimationFrame(
        () =>
          (document.querySelector(".leaflet-stop-pane").style.display = "block")
      );
      setTimeout(updateStopsOnMap, 0);
    } else {
      // eslint-disable-next-line
      if (store.state.mappi.hasLayer(store.state.stopLayer)) {
        requestAnimationFrame(
          () =>
            (document.querySelector(".leaflet-stop-pane").style.display =
              "none")
        );
      }
    }
  }

  store.state.mappi.on("zoomstart", () => {
    ZOOMING = true;
  });

  let stopUpped = false;
  store.state.mappi.on("zoomend", () => {
    requestAnimationFrame(() => {
      ZOOMING = false;
      touchMap();
      stopRoutine();

      const zoom = store.state.mappi.getZoom();
      if (zoom > 17) {
        stopUpped = true;
        document.querySelector(".leaflet-stop-pane").style.zIndex = "625";
      }

      if (zoom <= 17 && stopUpped) {
        stopUpped = false;
        document.querySelector(".leaflet-stop-pane").style.zIndex = "";
      }
    });
  });

  store.state.mappi.on("movestart", () => {
    MOVING = true;
  });

  store.state.mappi.on("moveend", () => {
    requestAnimationFrame(() => {
      MOVING = false;
      touchMap();
      stopRoutine();
    });
  });

  document.addEventListener("visibilitychange", () => {
    store.commit("visibilityChange");

    if (document.hidden) {
      console.log("VISIBILITY: HIDDEN");
      window.DISABLE_NETWORK = true;
    } else {
      console.log("VISIBILITY: ACTIVE");
      window.DISABLE_NETWORK = false;

      if (store.state.prefs.showLocation) {
        store.state.lc.start();
      }

      if (store.state.bus.showBus) {
        // if the bus doesn't exist on the map anymore when user reopens the app
        if (!store.state.live.latestBlob.lines[store.state.bus.showBus]) {
          store.commit("modalClosed");
        } else {
          store.dispatch("pollBus");
        }
      }

      if (store.state.bus.showBusRoute) {
        // if the bus doesn't exist on the map anymore when user reopens the app
        if (!store.state.live.latestBlob.lines[store.state.bus.showBusRoute]) {
          store.commit("removeBusRouteMarkers");
        } else {
          store.dispatch("pollBusRoute");
        }
      }

      if (store.state.ROUTER.mobile) {
        store.dispatch("pollFavStops");
      }

      if (store.state.stop.showStop !== 0) {
        store.dispatch("pollSingleStop", store.state.stop.showStop);
      }

      FORCE_BUS_LOC_UPDATE = true;
      fetchBlob()
        .then((blob) => {
          if (blob.ts > store.state.live.latestBlob.ts) {
            if (!window.LIVE_DISABLED) {
              store.commit("latestBlob", blob);
            }
          }
        })
        .then(() => {
          updateBussesOnMap(store.state.live.latestBlob.lines);
          store.commit("commitBlobTS", store.state.live.latestBlob.ts);
          FORCE_BUS_LOC_UPDATE = false;
        });
    }
  });

  function showLocationDeniedMessage(justClicked) {
    const d = document.getElementById("banneri");
    if (d.style.display === "block") {
      return;
    }
    if (justClicked) {
      d.innerText =
        "Olet aiemmin kieltänyt selainta jakamasta paikkatietoa Nyssetutkalle. Jotta Nyssetutka voi näyttää sijainnin, pitää sinun ensin poistaa ko. kielto selaimesta.";
    } else {
      d.innerText =
        "Voit jatkossa näyttää oman sijaintisi kartalla oikean alakulman painikkeesta";
    }

    d.style.display = "block";

    setTimeout(
      (() =>
        function () {
          d.style.display = "none";
        })(),
      5000
    );

    store.commit("prefsLocateDisable");
  }

  function stopAlert() {
    const d = document.getElementById("banneri");
    if (d.style.display === "block") {
      return;
    }
    d.innerText = "Pysäkit näytetään kartalla vain lähemmällä zuumitasolla";
    d.style.display = "block";

    setTimeout(
      (() =>
        function () {
          d.style.display = "none";
        })(),
      3000
    );
  }

  store.watch(
    () => store.state.live.latestBlob.error,
    () => {
      ADD_VUE_CONTAINER();
    }
  );

  store.watch(
    () => store.state.options.updatesFound,
    () => {
      ADD_VUE_CONTAINER();
    }
  );

  const routeOnMapFunc = (show) => {
    const d = document.getElementById("banneri-route");
    d.style.display = "block";

    if (show) {
      const obj = store.state.prefs.routing.routeOnMap;
      store.dispatch("showRouteOnMap", obj);

      const text = document.querySelector("#banneri-route .text-container");
      text.innerHTML = `Näytetään reitti:<br>${obj.nnfrom} => ${obj.nnto}`;

      const btnInfo = document.getElementById("banneri-route-btn-info");
      btnInfo.addEventListener("click", () => {
        store.dispatch("controlClicked", "routing");
      });

      const btnRemove = document.getElementById("banneri-route-btn-remove");
      btnRemove.addEventListener("click", () => {
        store.commit("removeRouteOnMap");
      });
    } else {
      d.style.display = "none";
    }
  };

  store.watch(() => store.state.prefs.routing.showRouteOnMap, routeOnMapFunc);
  if (store.state.prefs.routing.showRouteOnMap) {
    setTimeout(() => {
      routeOnMapFunc(true);
    }, 0);
  }

  window.onpopstate = () => {
    if (store.state.ROUTER.corner !== "") {
      store.commit("modalClosed");
    } else if (store.state.ROUTER.mobile) {
      store.commit("toggleMobile");
    } else {
      history.back();
    }
  };

  let bussesAdded = false;
  store.subscribe((mutation, state) => {
    if (mutation.type === "latestBlob") {
      touchMap();
      if (!bussesAdded) {
        bussesAdded = true;
        gaMark("bussesAdded");
      }
    } else if (mutation.type === "STATIC_STOPS") {
      stopRoutine();
      gaMark("stopsAdded");
    } else if (mutation.type === "prefs" && mutation.payload === "showStops") {
      if (state.prefs.showStops) {
        state.stopLayer.addTo(state.mappi);
        stopRoutine();
      } else {
        state.mappi.removeLayer(state.stopLayer);
      }

      if (state.mappi.getZoom() < 15) {
        stopAlert();
      }
    } else if (
      mutation.type === "prefs" &&
      mutation.payload === "showOnlyFavStops"
    ) {
      if (store.state.prefs.showOnlyFavStops) {
        store.commit("removeStops");
      }
      updateStopsOnMap();
    } else if (mutation.type === "prefsGhosts") {
      if (state.prefs.showGhosts) {
        FORCE_BUS_LOC_UPDATE = true;
        touchMap();
        FORCE_BUS_LOC_UPDATE = false;
      } else {
        removeGhosts();
      }
    } else if (mutation.type === "initFilters") {
      ADD_VUE_CONTAINER();
    } else if (
      mutation.type === "initBusData" &&
      mutation.payload.showBusRoute === true
    ) {
      ADD_VUE_CONTAINER();
    } else if (mutation.type === "objClicked") {
      window.history.pushState(null, null, "/");
      ADD_VUE_CONTAINER();
      trackObjClick(
        mutation.payload.target.options.type,
        mutation.payload.target.options.code
      );
    } else if (mutation.type === "toggleMobile" && mutation.payload === true) {
      window.history.pushState(null, null, "/");
      ADD_VUE_CONTAINER();
      store.dispatch("pollFavStops");
    } else if (mutation.type === "setMobileView") {
      ADD_VUE_CONTAINER();
    } else if (mutation.type === "locationUserDenied") {
      showLocationDeniedMessage(mutation.payload);
    }
  });
};
