import L from "leaflet";
import store, { STOP_IMG, FAV_STOP_IMG } from "./index";
import { graphSTOP, graphSTOPLines } from "../fetch";
import { disableStops } from "../globalFlags";
import { trackFeatureUsage } from "../ga";
import { animService } from "./animLoop";

const isFavStop = (code) => store.state.prefs.fav.stop.indexOf(code) !== -1;

export function blobInsideBounds(NE, SW, blob) {
  const coff = 0.001;
  if (
    blob.lon < NE.lng + coff &&
    blob.lon > SW.lng - coff &&
    blob.lat < NE.lat + coff &&
    blob.lat > SW.lat - coff
  ) {
    // console.log(NE, SW, blob)
    return true;
  }
  return false;
}

export function distanceLessThan(p1, p2, dist) {
  if (
    Math.sqrt(Math.pow(p1.lat - p2.lat, 2) + Math.pow(p1.lng - p2.lng, 2)) <
    dist
  ) {
    return true;
  }
  return false;
}

function calcStopsToAdd(lon1, lat1, lon2, lat2) {
  // console.timeStamp('calcStopsToAdd');
  return new Promise((resolve) => {
    const l = [];
    window.ALL_STOPS.forEach((elem) => {
      if (
        elem.lon < lon2 &&
        elem.lon > lon1 &&
        elem.lat > lat1 &&
        elem.lat < lat2
      ) {
        l.push(elem);
      }
    });
    resolve(l);
  });
}

L.DivDataIcon = L.DivIcon.extend({
  createIcon(oldIcon) {
    const div = L.DivIcon.prototype.createIcon.call(this, oldIcon);

    if (this.options.stopId) {
      div.dataset.stopId = this.options.stopId;
    }
    return div;
  },
});

L.divDataIcon = function (options) {
  return new L.DivDataIcon(options);
};

export const stopIconInnerHTML = (code) =>
  `<img src="${isFavStop(code) ? FAV_STOP_IMG : STOP_IMG}">`;

function makeSingleDiv(elem) {
  const baseMarker = L.marker([elem.lat, elem.lon], {
    icon: new L.divDataIcon({
      className: "stopMarker",
      html: stopIconInnerHTML(elem.code),
      stopId: elem.code,
      iconSize: [30, 30],
      iconAnchor: [15, 15],
    }),
    code: elem.code,
    type: "stop",
    pane: "stopPane",
  });

  baseMarker.on("click", (evt) => {
    store.dispatch("stopClicked", evt);
  });
  baseMarker.on("contextmenu", () => {
    store.dispatch("stopLongPressed", elem.code);
  });

  return baseMarker;
}

function makeStopMarkers(stopArr) {
  // console.timeStamp('makeStopMarkers');
  return new Promise((resolve) => {
    const stopElems = [];
    stopArr.forEach((elem) => {
      if (!(elem.code in store.state.live.STOPS_ADDED)) {
        if (store.state.prefs.showOnlyFavStops) {
          if (!isFavStop(elem.code)) {
            return;
          }
        }
        const sm = makeSingleDiv(elem);
        stopElems.push(sm);
      }
    });
    resolve(stopElems);
  });
}

export function updateStopsOnMap() {
  if (disableStops) {
    return;
  }
  const coords = store.state.mappi.getBounds();
  calcStopsToAdd(
    coords._southWest.lng,
    coords._southWest.lat,
    coords._northEast.lng,
    coords._northEast.lat
  )
    .then(makeStopMarkers)
    .then((stopMarkerVec) => {
      setTimeout(() => animService.addStops(stopMarkerVec), 0);
    });
}

// ////////////////////////////////////////////////////////////
//
// above: stop routines for animloop etc. + utils
// below: click handlers etc.
//
//

export const stopClicked = ({ commit, dispatch }, evt) => {
  const stopCode = evt.target.options.code;
  console.log(`stopClicked ${stopCode}`);
  commit("initStopPanel", stopCode);
  commit("objClicked", evt);
  dispatch("pollSingleStop", stopCode);
};

export const loadStopsNearUser = ({ commit, state }, payload) => {
  console.log("loadStopsNearUser");
  commit("userLocationFound", payload);
  if (!state.live.nearStopsLoadedOnce) {
    const nearStops = window.ALL_STOPS.filter((elem) =>
      distanceLessThan(payload, { lat: elem.lat, lng: elem.lon }, 0.004)
    );
    // console.log('nearStops:');
    // console.log(nearStops);
    nearStops.forEach((stop) => {
      graphSTOP(stop.code).then((stopData) => {
        commit("stopData", stopData.data.stop);
      });
    });
  }
  commit("userLocationFound", payload);
};

export const showStopOnMap = ({ commit, state }, stopCode) => {
  console.log("showStopOnMap");
  const { lat, lon } = window.ALL_STOPS_BY_CODE[stopCode];
  if (state.mobileUser) {
    commit("toggleMobile", false);
  }
  state.mappi.setView({ lat, lng: lon }, 16);
};

export const stopLongPressed = ({ commit }, id) => {
  console.log(`stopLonPressed ${id}`);

  commit("initFilters", id);

  graphSTOPLines(id).then((res) => {
    const lines = [];
    res.data.stop.lines.forEach((line) => {
      lines.push(line.split(" ")[0]);
    });
    store.commit("filterLines", { lines, stopId: id });
  });

  trackFeatureUsage("filterLines");
};

const timeDeltaInMinutes = (timeString) => {
  const time = new Date();
  const hh = time.getHours();
  const mm = time.getMinutes();

  const tarr = timeString.split(":");
  let fhh = parseInt(tarr[0]);
  const fmm = parseInt(tarr[1]);
  // jos 23 ja 00
  if (fhh < hh) {
    fhh += 24;
  }
  const hDelta = fhh - hh;
  let mDelta;

  if (hDelta === 0) {
    mDelta = fmm - mm;
  } else {
    mDelta = 60 * (hDelta - 1) + (60 - mm) + fmm;
  }

  return mDelta;
};

export const addBusRouteMarkers = ({ state }, { stopArr }) => {
  if (!state.bus.showBusRoute) return;

  // TODO: this is not optimal; always remove popups and readd them
  state.stopRouteLayer.clearLayers();

  requestAnimationFrame(() => {
    const stops = stopArr.map((elem) => ({
      time: elem.arrival,
      ...window.ALL_STOPS_BY_CODE[elem.code],
    }));
    stops.forEach((stop) => {
      const delta = timeDeltaInMinutes(stop.time);
      if (delta > -1) {
        const pop = L.popup({
          className: "bus-route-popup",
          minWidth: 0,
          closeButton: false,
          autoPan: false,
          closeOnClick: false,
        })
          .setLatLng([stop.lat, stop.lon])
          .setContent(
            `${stop.time}<br><span class="route-text">${delta} min</span>`
          );
        state.stopRouteLayer.addLayer(pop);
      }
    });
  });
};
