export const toggleMobile = (state, show) => {
  state.ROUTER.mobile = show;
};

export const setMobileView = (state, view) => {
  state.ROUTER.mobileView = view;
};

export const HIDE_GLOBAL_INFO_MSG = (state) => {
  state.ROUTER.showGlobalInfoMsg = false;
};
