import { TrackJS } from "trackjs";

import { fetchBlob } from "../fetch";
import store from "./index";
import { disableBusses } from "../globalFlags";
import { showNetworkError, hideNetworkError } from "../errorMessages";

export default (API_URL_BASE_DOMAIN) => {
  console.log(`apiPlugin init ${API_URL_BASE_DOMAIN}`);

  let errorCount = 0;
  function pollBlob() {
    if (disableBusses || window.DISABLE_NETWORK) {
      return;
    }
    fetchBlob()
      .then((blob) => {
        if (blob.ts > store.state.live.latestBlob.ts) {
          store.commit("latestBlob", blob);
        }
        if (errorCount > 10) {
          hideNetworkError();
        }
        errorCount = 0;
      })
      .catch(() => {
        errorCount += 1;
        console.log(`/api/blob not responding for ${errorCount}`);

        if (errorCount > 10) {
          showNetworkError();
        }
      });
  }

  // Vuex lifecycle
  setTimeout(pollBlob, 0);

  let intervalId = setInterval(pollBlob, 1100);

  let metaCount = 0;
  document.addEventListener("visibilitychange", () => {
    if (document.hidden) {
      console.log("cancelling /api/blob interval");
      clearInterval(intervalId);
      TrackJS.addMetadata(`hidden-${metaCount}`, `${new Date()}`);
    } else {
      console.log("restarting /api/blob interval");
      intervalId = setInterval(pollBlob, 1100);
      TrackJS.addMetadata(`shown-${metaCount}`, `${new Date()}`);
      metaCount += 1;
    }
  });

  return (store) => {
    store.subscribe((mutation) => {
      // console.log(`apiPlugin :: mutation :: ${mutation.type}`);
      if (mutation.type === "COMMIT_INITIAL_STATE") {
        console.log("commit initial state mutation detected");
        store.commit("latestBlob", mutation.payload);
      }
    });
  };
};
