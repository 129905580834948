/* eslint-disable no-param-reassign */

import Vue from "vue";
import { updateFavoriteBussesOnMap } from "./prefMutations";
import { dateToDateStr, secondsToString, dateStrToDate } from "../utils";

export const visibilityChange = (state) => {
  if (document.visibilityState === "visible") {
    state.ROUTER.visible = true;
  } else {
    state.ROUTER.visible = false;
  }
};

export const loadingBusStops = (state) => {
  console.log("loadingBusStops");
  state.showMessage = !state.showMessage;
};

export const messageAdd = (state, { id, msg }) => {
  console.log("messageAdd");
  state.messages.next += 1;
  state.messages.show.push(id);
  state.messages.q[id] = msg;
};

export const messageRemove = (state, id) => {
  console.log(`messageRemove :: ${id}`);
  state.messages.show = state.messages.show.filter((elem) => elem !== id);
};

export const ATTACH_LISTENERS = () => {
  // mapPlugin hoitaa tämän, no-op tässä
  console.log("ATT");
};

export const COMMIT_INITIAL_STATE = () => {
  console.log("COMM I S");
  // mapPlugin hoitaa tämän
};

export const STATIC_STOPS = () => {
  console.log("STATIC_STOPS -- no-op");
};

export const latestBlob = (state, newBlob) => {
  Object.keys(newBlob.lines).forEach((lineKey) => {
    const uusi = newBlob.lines[lineKey];
    const vanha = state.live.latestBlob.lines[lineKey];
    if (vanha === undefined) {
      uusi.KUVA = uusi.b === 0 ? "INIT" : "MOVE";
    } else {
      uusi.b = uusi.b === 0 ? vanha.b : uusi.b;
      if (uusi.lat === vanha.lat && uusi.lon === vanha.lon) {
        uusi.KUVA = vanha.KUVA === "INIT" ? "INIT" : "STOP";
      } else {
        uusi.KUVA = "MOVE";
      }
    }
  });
  state.live.latestBlob = newBlob;
};

export const commitBlobTS = (state, ts) => {
  state.live.ts = ts;
};

export const CHANGE_MAP_STYLE = () => {
  const current = localStorage.getItem("MAP_STYLE") || "neighbourhood";

  if (current === "neighbourhood") {
    localStorage.setItem("MAP_STYLE", "transport");
  } else {
    localStorage.setItem("MAP_STYLE", "neighbourhood");
  }
  window.location.reload();
};

const commitLines = (state, stop) => {
  const lines = stop.routes.map((r) => r.shortName);
  const prev = state.stop.stops[stop.code];
  Vue.set(state.stop.stops, stop.code, { ...prev, ...stop, lines });
};

export const stopTimetableData = (state, { dateStr, data }) => {
  let date = dateStrToDate(dateStr);
  commitLines(state, data);
  const { code } = data;
  const table = data.stoptimesForServiceDate;
  const deps = [];
  table.forEach((service) => {
    const { pattern, stoptimes } = service;
    const {
      route: { shortName },
    } = pattern;
    stoptimes
      .filter((t) => t.pickupType !== "NONE")
      .forEach((stoptime) => {
        const { serviceDay, scheduledDeparture } = stoptime;
        const d = new Date(serviceDay * 1000);
        if (!date) date = d;
        const time = secondsToString(scheduledDeparture);
        deps.push(`${time}/${shortName}`);
      });
  });

  const hours = {};
  const order = [];
  deps.sort().forEach((d) => {
    const k = d.slice(0, 2);
    if (!order.includes(k)) order.push(k);
    if (!hours[k]) hours[k] = { hour: k, departures: [] };
    hours[k].departures.push(d.slice(3));
  });
  const result = order.map((hkey) => hours[hkey]);
  const key = dateToDateStr(date);
  if (!(code in state.stop.timetables)) {
    Vue.set(state.stop.timetables, code, { [key]: result });
  } else {
    Vue.set(state.stop.timetables[code], key, result);
  }
};

export const stopData = (state, stop) => {
  stop.ts = new Date().getTime();
  commitLines(state, stop);
  Vue.set(state.stop.stops, stop.code, {
    ...state.stop.stops[stop.code],
    ...stop,
    loading: false,
  });
};

export const userLocationFound = (state, payload) => {
  state.live.user.lat = payload.lat;
  state.live.user.lng = payload.lng;
  state.live.nearStopsLoadedOnce = true;
};

export const initStopPanel = (state, stopCode) => {
  state.stop.showStop = stopCode;
};

export const initBusData = (state, { tklId, showPopup, showBusRoute }) => {
  const latest = state.live.latestBlob.lines[tklId];
  const panelObj = {};
  panelObj.code = latest.lineRef;
  panelObj.name = latest.dest;
  panelObj.ghostRef = latest.ghostRef;
  panelObj.onwardCalls = [];
  panelObj.ts = 0;
  panelObj.loading = true;
  if (latest.ghost) {
    panelObj.ghost = true;
  }
  if (showPopup) {
    state.bus.showBus = tklId;
  }
  if (showBusRoute) {
    state.bus.showBusRoute = tklId;
  }
  Vue.set(state.bus.calls, tklId, panelObj);
};

export const hideBusPanel = (state) => {
  state.bus.showPanel = false;
};

export const busPanelData = (state, payload) => {
  state.bus.calls[payload.tklId].ts = new Date().getTime();
  state.bus.calls[payload.tklId].onwardStops = payload.stops.filter((s) => !!s);
  state.bus.calls[payload.tklId].loading = false;
  // Vue.set(state.bus.calls,
  // payload.tklId,
  // { ts: (new Date()).getTime(),
  // onwardStops: payload.stops,
  // loading: false,
  // });
  // state.bus.calls[payload.tklId].onwardStops = payload.stops;
};

export const objClicked = (state, evt) => {
  console.log(`objClicked: ${evt.target.options.type}`);
  state.ROUTER.corner = evt.target.options.type;
};

export const modalClosed = (state) => {
  state.bus.showBus = undefined;
  state.stop.showStop = 0;
  state.ROUTER.corner = "";
};

export const settingsShowHide = (state) => {
  state.ROUTER.settings = !state.ROUTER.settings;
};

export const showInfoPage = (state) => {
  state.ROUTER.info = !state.ROUTER.info;
};

export const lazyLoad = (state) => {
  state.ROUTER.lazyLoaded = true;
  console.log("lazyLoad OK!");
};

export const initFilters = (state) => {
  state.filters.show = true;
};

export const filterLines = (state, { stopId, lines }) => {
  if (!(stopId in state.filters.stops)) {
    Vue.set(state.filters.stops, stopId, [...new Set(lines)]);
  }
  lines.forEach((line) => {
    if (state.filters.lines.indexOf(line) === -1) {
      state.filters.lines.push(line);
    }
  });

  state.busLayer.eachLayer((bus) => {
    const shouldBeHidden = state.filters.lines.indexOf(bus.options.code) === -1;
    const icon = bus.getElement();
    if (icon) {
      icon.style.display = shouldBeHidden ? "none" : "";
    }
  });
};

export const resetFilters = (state) => {
  state.filters = { show: false, lines: [], stops: {} };
  // this will apply display none/block depending on
  // showOnlyFavBusses setting
  updateFavoriteBussesOnMap(state);
};

export const removeBusRouteMarkers = (state) => {
  state.stopRouteLayer.clearLayers();
  state.bus.showBusRoute = undefined;
};

export const locationUserDenied = () => {
  console.log("locationUserDenied :: no-op");
};

export const routeOnMap = (state, routeObj) => {
  console.log("routeOnMap");
  state.prefs.routing.showRouteOnMap = true;
  state.prefs.routing.routeOnMap = routeObj;
};

export const removeRouteOnMap = (state) => {
  console.log("removeRouteOnMap");
  state.prefs.routing.showRouteOnMap = false;
  state.prefs.routing.routeOnMap = {};
  state.routeLayer.eachLayer((l) => {
    state.routeLayer.removeLayer(l);
  });
};

export const removeStops = (state) => {
  console.log("removeStops");
  state.stopLayer.eachLayer((layer) => {
    state.stopLayer.removeLayer(layer);
  });
  state.live.STOPS_ADDED = {};
};

export const updatesFound = (state) => {
  state.options.updatesFound = true;
};
