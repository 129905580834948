import "autotrack/lib/plugins/page-visibility-tracker";
import Vue from "vue";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faCaretRight,
  faCaretDown,
  faPlus,
  faExternalLinkAlt,
  faCogs,
  faInfo,
  faBus,
  faStar,
  faRoute,
  faLocationArrow,
  faMapMarkerAlt,
  faExpandArrowsAlt,
  faCommentDots,
} from "@fortawesome/free-solid-svg-icons";
import { faStar as farStar } from "@fortawesome/free-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

// import "mapbox-gl/dist/mapbox-gl.css";

import initSW from "./swLogic";
import store from "./store/index";
import { initLoop } from "./store/animLoop";
import spinner from "./components/Spinner";
import Close from "./components/Close";
import { km, min, colon, stripTime } from "./components/routing/filters";
import { initFonts, iosFix, fbUserAgentNotification, longPress } from "./utils";
import { trackTimings, trackFirstTimer, trackBundleUsage } from "./ga";

library.add(
  faPlus,
  faCaretDown,
  faCaretRight,
  faCogs,
  faInfo,
  faBus,
  faStar,
  farStar,
  faRoute,
  faLocationArrow,
  faCommentDots,
  faMapMarkerAlt,
  faExternalLinkAlt,
  faExpandArrowsAlt
);
Vue.component("font-awesome-icon", FontAwesomeIcon);

require("./assets/initial.css");
require("./assets/custom.css");

iosFix();
fbUserAgentNotification();

initLoop();

Vue.component("Spinner", spinner);
Vue.component("Close", Close);
Vue.filter("km", km);
Vue.filter("min", min);
Vue.filter("colon", colon);
Vue.filter("stripTime", stripTime);
Vue.directive("longpress", longPress);

initFonts();

const STOP_JSON_URL = require("./assets/generated_stops_wgs_winter_ext.json");

if (window.STOPS_FETCHED !== true) {
  console.log("STATIC_STOPS::requesting NEEDED in main.js");
  fetch(STOP_JSON_URL)
    .then((res) => res.json())
    .then((data) => {
      console.log("STATIC_STOPS::received");
      store.commit("STATIC_STOPS", data);
      window.ALL_STOPS = data;
      window.ALL_STOPS_BY_CODE = {};
      data.forEach((elem) => {
        window.ALL_STOPS_BY_CODE[elem.code] = elem;
      });
    });
} else {
  console.log("STATIC_STOPS::requesting NOT NEEDED in main.js; -> committing");
  store.commit("STATIC_STOPS", window.ALL_STOPS);
}

if (window.INITIAL_BLOB) {
  if ("ts" in window.INITIAL_BLOB && "lines" in window.INITIAL_BLOB) {
    console.log("initial blob seems to be found.....");
    store.commit("COMMIT_INITIAL_STATE", window.INITIAL_BLOB);
  }
} else {
  console.log("no initial blob found");
}

if (window.BUSSES_FETCHED) {
  store.commit("COMMIT_INITIAL_STATE", window.INITIAL_BUS_DATA);
}

// laitetaan favorites näkyviin, jos ei mobiili
//

if (!store.state.mobileUser) {
  if (store.state.prefs.showFavOnStartup) {
    store.commit("setMobileView", "favorites");
    store.commit("toggleMobile", true);
  }
}

store.commit("prefsVisit");

window.addEventListener("load", () => {
  console.log("WINDOW::LOAD");
  initSW();
  trackTimings();
  trackFirstTimer();
  trackBundleUsage();
  window.UPDATES_LATEST_MSG_ID = 109;
  store.dispatch("checkUpdates");
  store.dispatch("checkGlobalInfoMsg");

  setTimeout(() => {
    store.commit("checkStopConsistency");
  }, 10000);
});
