/* eslint-disable no-unused-vars */

import { favoriteBus, stripLetters, secondsToString } from "../utils";

export const queue = (state) =>
  state.messages.show
    .map((elem) => ({
      msg: state.messages.q[elem],
      id: elem,
    }))
    .reverse()
    .slice(0, 2);

export const showMessage = (state) => state.messages.show.length > 0;

export const latestBlob = (state) => state.live.latestBlob;

export const stop = (state, getters) => (code) => {
  const monitor = state.stop.stops[code];
  if (monitor && monitor.lines) {
    return {
      ...monitor,
      loadingMonitor: !monitor.stoptimesWithoutPatterns,
      loading: false,
    };
  }
  return { loading: true };
};

export const favBus = (state, getters) => (lineCode) =>
  favoriteBus(state, lineCode);

export const pollableStops = (state, getters) => {
  const favStops = [].concat(state.prefs.fav.stop);
  Object.keys(state.prefs.fav.virtualStops).forEach((virtKey) => {
    state.prefs.fav.virtualStops[virtKey].stopCodes.forEach((vsk) => {
      if (favStops.indexOf(vsk) === -1) {
        favStops.push(vsk);
      }
    });
  });

  return favStops;
};

export const stopLineCodes = (state, getters) => (stopCode) => {
  const stop = getters.stop(stopCode);
  if (!("lines" in stop)) {
    return [];
  }
  return stop.lines.map((line) => {
    if (line.indexOf(" ") === -1) {
      return stripLetters(line);
    }
    return stripLetters(line.split(" ")[0]);
  });
};

export const stopName = (state) => (stopCode) => {
  if (stopCode in window.ALL_STOPS_BY_CODE) {
    return window.ALL_STOPS_BY_CODE[stopCode].name;
  }
  return "(ei nimeä)";
};

export const stopZone = (state) => (stopCode) => {
  if (stopCode in window.ALL_STOPS_BY_CODE) {
    return window.ALL_STOPS_BY_CODE[stopCode].zone;
  }
  return "";
};
