/* eslint-disable arrow-body-style */
import L from "leaflet";

import store from "./index";
import { animService } from "./animLoop";
import { blobInsideBounds } from "./stopActions";
// NEVER MUTATE THIS VARIABLE INSIDE THIS MODULE
// mapPlugin takes care of turning it on/off
import { FORCE_BUS_LOC_UPDATE } from "./mapPlugin";
import { trackFeatureUsage } from "../ga";
import { favoriteBus } from "../utils";

const IMAGES = {
  bus: {
    dir1: require("../assets/svg/up6moveDir1_45px_min.svg"),
    dir2: require("../assets/svg/up6moveDir2_45px_min.svg"),
  },
  tram: {
    dir1: require("../assets/svg/up6moveDir1_45px_tram_min.svg"),
    dir2: require("../assets/svg/up6moveDir2_45px_tram_min.svg"),
  },
  ghost: require("../assets/svg/up6moveGhost_45px_min.svg"),
};

const TRAMS = ["1", "3"];

export function mapAddBus(TKL_ID, b) {
  if (TKL_ID in store.state.live.BUS_MARKERS || !b) return;

  const imgObj = IMAGES[TRAMS.includes(b.lineRef) ? "tram" : "bus"];
  let imgUrli;
  if (b.ghost) {
    imgUrli = IMAGES.ghost;
  } else if (store.state.prefs.useTwoColorsForBusses) {
    imgUrli = b.dir === "2" ? imgObj.dir2 : imgObj.dir1;
  } else {
    imgUrli =
      store.state.prefs.singleBusColor === "vihreä" ? imgObj.dir1 : imgObj.dir2;
  }
  const delta = store.state.prefs.realPhysics ? 180 : 0;
  const html = `<img class="bus-arrow-svg" style="transform:rotate(${
    b.b + delta
  }deg);" src="${imgUrli}">
              <span class="bus-line-number-text ${
                b.lineRef.length > 2 ? "narrow" : ""
              }">${b.lineRef}</span>`;
  const nicon = new L.DivIcon({
    className: "bus-icon",
    html,
  });

  const mm = L.marker([b.lat, b.lon], {
    icon: nicon,
    tklId: TKL_ID,
    type: "bus",
    ghost: b.ghost === true,
    code: b.lineRef,
  });

  mm.on("click", (evt) => {
    store.dispatch("busClicked", evt);
  });
  mm.on("contextmenu", () => {
    store.dispatch("busLongPressed", TKL_ID);
  });

  const t = mm.addTo(store.state.busLayer);

  // if the bus is being added *WHILE* stop filtering or favBusses in use
  if (
    (store.state.filters.show &&
      store.state.filters.lines.indexOf(b.lineRef) === -1) ||
    (store.state.prefs.showOnlyFavBusses &&
      !favoriteBus(store.state, b.lineRef))
  ) {
    const icon = t.getElement();
    if (icon) {
      icon.style.display = "none";
    }
  }

  store.state.live.BUS_MARKERS[TKL_ID] = mm;
}

export function mapRemoveBus(busMarker) {
  // console.log(`mapRemoveBus ${busMarker.options.code} - ${busMarker.options.tklId}`);
  store.state.busLayer.removeLayer(busMarker);
  delete store.state.live.BUS_MARKERS[busMarker.options.tklId];
}

/* eslint-disable no-underscore-dangle, no-param-reassign */
export function updateLocation(elem, blob) {
  if (!elem || !blob) return; // might be removed before we reach this
  elem.setLatLng([blob.lat, blob.lon]);
  const delta = store.state.prefs.realPhysics ? 180 : 0;
  elem._icon.children[0].style.transform = `rotate(${blob.b + delta}deg)`;
}

export function removeGhosts() {
  store.state.busLayer.eachLayer((busMarker) => {
    if (busMarker.options.ghost) {
      mapRemoveBus(busMarker);
    }
  });
}

function calc(loc, vecBlob) {
  let addIds = Object.keys(vecBlob).filter((TKL_ID) => {
    return (
      !(TKL_ID in store.state.live.BUS_MARKERS) &&
      blobInsideBounds(loc._northEast, loc._southWest, vecBlob[TKL_ID])
    );
  });

  if (!store.state.prefs.showGhosts) {
    addIds = addIds.filter((id) => !vecBlob[id].ghost);
  }

  const updateIds = Object.keys(vecBlob).filter((TKL_ID) => {
    return (
      TKL_ID in store.state.live.BUS_MARKERS &&
      (FORCE_BUS_LOC_UPDATE ||
        blobInsideBounds(loc._northEast, loc._southWest, vecBlob[TKL_ID]))
    );
  });

  const removeMarkers = [];

  store.state.busLayer.eachLayer((busMarker) => {
    if (!(busMarker.options.tklId in vecBlob)) {
      removeMarkers.push(busMarker);
    }
  });

  return { addIds, updateIds, removeMarkers };
}

export function updateBussesOnMap(vecBlob) {
  const loc = store.state.mappi.getBounds();
  const { addIds, updateIds, removeMarkers } = calc(loc, vecBlob);

  if (addIds.length > 0) {
    animService.addBusses(addIds);
  }
  if (updateIds.length > 0) {
    animService.updateBusses(updateIds);
  }
  if (removeMarkers.length > 0) {
    animService.removeBusses(removeMarkers);
  }
}

export const busClicked = ({ dispatch, commit }, evt) => {
  commit("objClicked", evt);
  commit("initBusData", { tklId: evt.target.options.tklId, showPopup: true });
  dispatch("pollBus");
};

export const busLongPressed = ({ commit, dispatch, state }, tklId) => {
  console.log("busLongPressed");

  if (state.bus.showBusRoute) {
    commit("removeBusRouteMarkers");
  }

  commit("initBusData", { tklId, showBusRoute: true });
  dispatch("pollBusRoute");

  trackFeatureUsage("busRoute");
};
