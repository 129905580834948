/* eslint-disable no-param-reassign */
import Vue from "vue";
import { favoriteBus, stripLetters } from "../utils";
import { trackSettingsUsage } from "../ga";
import { stopIconInnerHTML } from "./stopActions";

export const prefsReset = (state) => {
  state.prefs = {
    showLocation: true,
    locationAccepted: false,
    showStops: true,
    showOnlyFavStops: false,
    showGhosts: true,
    realPhysics: false,
    showFavOnStartup: true,
    showOnlyFavBusses: false,
    useTwoColorsForBusses: true,
    singleBusColor: "vihreä",
    fav: {
      stop: [],
      stopOrder: [],
      hiddenStops: [],
      stopNames: {},
      busses: [],
      stopFilters: [],
      virtualStops: {},
    },
    updates: {
      visitCount: state.prefs.updates.visitCount || 0,
      seenMsgs: state.prefs.updates.seenMsgs || [],
    },
    routing: {
      searches: [],
    },
  };
};

// ////////////////////////////////////////////////////////////////
// UPDATES

export const prefsVisit = (state) => {
  // console.log('prefsVisit');
  if (state.prefs.updates.visitCount === 0) {
    window.GA_VISIT_FIRST_TIME = true;
    console.log("FIRST TIME VISIT");
  } else {
    window.GA_VISIT_FIRST_TIME = false;
  }
  window.GA_VISIT_COUNT = state.prefs.updates.visitCount;
  state.prefs.updates.visitCount += 1;
};

export const prefsMsgHidden = (state, msgId) => {
  console.log(`prefsMsgHidden ${msgId}`);
  state.prefs.updates.seenMsgs.push(msgId);
};

// ////////////////////////////////////////////////////////////////

const resetAllBusses = (state) => {
  Vue.set(state.live.latestBlob, "lines", {});
  Vue.set(state.live, "BUS_MARKERS", {});
  state.busLayer.clearLayers();
};

export const prefs = (state, type) => {
  switch (type) {
    case "showFavOnStartup":
      state.prefs.showFavOnStartup = !state.prefs.showFavOnStartup;
      trackSettingsUsage("showFavOnStartup");
      break;
    case "useTwoColorsForBusses":
      state.prefs.useTwoColorsForBusses = !state.prefs.useTwoColorsForBusses;
      resetAllBusses(state);
      trackSettingsUsage("useTwoColorsForBusses");
      break;

    case "showStops":
      state.prefs.showStops = !state.prefs.showStops;
      trackSettingsUsage("stops");
      break;

    case "showOnlyFavStops":
      state.prefs.showOnlyFavStops = !state.prefs.showOnlyFavStops;
      trackSettingsUsage("stopsOnlyFav");
      break;

    default:
      console.log(type);
      throw new Error("prefType not found from switch");
  }
};

export const prefsSingleBusColor = (state, color) => {
  state.prefs.singleBusColor = color;
  resetAllBusses(state);
  trackSettingsUsage("singleBusColor");
};

export const prefsGhosts = (state) => {
  state.prefs.showGhosts = !state.prefs.showGhosts;
  trackSettingsUsage("ghosts");
};
export const prefsLocate = (state) => {
  state.prefs.showLocation = !state.prefs.showLocation;
  trackSettingsUsage("locate");

  if (state.prefs.showLocation) {
    state.lc.start();
  }
};
export const prefsLocateDisable = (state) => {
  console.log("prefsLocateDisable");
  state.prefs.showLocation = false;
  // ga kutsutaan locationFoundHandlerissa
};
export const prefsAcceptLocation = (state) => {
  console.log("prefsAcceptLocation");
  state.prefs.locationAccepted = true;
  // ga kutsutaan locationFoundHandlerissa
};

export const prefsUserLocationFound = (state, loc) => {
  console.log("userLocation committed");
  state.live.userLocation = loc;
};

export const prefsPhysics = (state) => {
  state.prefs.realPhysics = !state.prefs.realPhysics;
  trackSettingsUsage("physics");
};

export const prefsFavoriteChange = (state, { type, id }) => {
  // HANDLE BUS FAV CHANGES
  if (type === "bus") {
    id = stripLetters(id);
    if (state.prefs.fav.busses.indexOf(id) === -1) {
      state.prefs.fav.busses.push(id);
    } else {
      state.prefs.fav.busses = state.prefs.fav.busses.filter(
        (elem) => elem !== id
      );
    }

    // if favs have changed in any way, make sure they're displayed correctly
    updateFavoriteBussesOnMap(state);
  } else {
    // HANDLE STOP FAV CHANGES

    if (state.prefs.fav.stop.indexOf(id) === -1) {
      state.prefs.fav.stop.push(id);
      state.prefs.fav.stopOrder = [{ normal: true, code: id }].concat(
        state.prefs.fav.stopOrder
      );
    } else {
      state.prefs.fav.stop = state.prefs.fav.stop.filter((elem) => elem !== id);
      state.prefs.fav.stopOrder = state.prefs.fav.stopOrder.filter(
        (e) => e.normal === false || (e.normal === true && e.code !== id)
      );
      state.prefs.fav.hiddenStops = state.prefs.fav.hiddenStops.filter(
        (elem) => elem !== id
      );
    }

    updateFavoriteStopsOnMap(state);
  }
  trackSettingsUsage(type === "bus" ? "bus" : "stop");
};

export const prefsStopOrder = (state, arr) => {
  console.log("mutation");
  console.log(state.prefs.fav.stopOrder);
  Vue.set(state.prefs.fav, "stopOrder", arr);
  console.log(state.prefs.fav.stopOrder);
  trackSettingsUsage("favStopReorder");
};

export const prefsHideStop = (state, stopCode) => {
  if (state.prefs.fav.hiddenStops.indexOf(stopCode) !== -1) {
    state.prefs.fav.hiddenStops = state.prefs.fav.hiddenStops.filter(
      (elem) => elem !== stopCode
    );
  } else {
    state.prefs.fav.hiddenStops.push(stopCode);
  }
  trackSettingsUsage("favStopShowHide");
};

export const prefsFavBussesReset = (state) => {
  state.prefs.fav.busses = [];
};

export const prefsShowOnlyFavBusses = (state) => {
  state.prefs.showOnlyFavBusses = !state.prefs.showOnlyFavBusses;
  updateFavoriteBussesOnMap(state);
  trackSettingsUsage("showOnlyFavBusses");

  document.querySelector(".custom-control-button .icon-bus").style.color = state
    .prefs.showOnlyFavBusses
    ? "tomato"
    : "";
};

export const updateFavoriteBussesOnMap = (state) => {
  if (state.prefs.showOnlyFavBusses) {
    state.busLayer.eachLayer((bus) => {
      const icon = bus.getElement();
      if (icon) {
        icon.style.display = favoriteBus(state, bus.options.code) ? "" : "none";
      }
    });
  } else {
    state.busLayer.eachLayer((bus) => {
      const icon = bus.getElement();
      if (icon) {
        icon.style.display = "";
      }
    });
  }
};

export const updateFavoriteStopsOnMap = (state) => {
  state.stopLayer.eachLayer((stop) => {
    stop._icon.innerHTML = stopIconInnerHTML(stop.options.code);
  });
};

export const prefsFlipFavBusses = (state) => {
  if (state.prefs.fav.busses.length === 0) {
    state.prefs.fav.busses = state.live.BUSSES_ALL.slice();
  } else if (state.prefs.fav.busses.length === state.live.BUSSES_ALL.length) {
    state.prefs.fav.busses = [];
  } else {
    const uu = state.live.BUSSES_ALL.filter(
      (line) => state.prefs.fav.busses.indexOf(line) === -1
    );
    state.prefs.fav.busses = uu;
  }
};

export const prefsStopCustomName = (state, { code, name }) => {
  if (name === "") {
    Vue.delete(state.prefs.fav.stopNames, code);
  } else {
    Vue.set(state.prefs.fav.stopNames, code, name);
  }
  trackSettingsUsage("favStopCustomName");
};

// ////////////////////////////////////////////////////////
//
//  ROUTING
//

export const prefsAddRoutingSearch = (state, obj) => {
  console.log("prefsAddRoutingSearch");

  const wIndex = state.prefs.routing.searches.findIndex(
    (e) => e.idFrom === obj.idFrom && e.idTo === obj.idTo
  );

  // lisätään vain jos samanlaista ei vielä ole
  if (wIndex === -1) {
    state.prefs.routing.searches.push(obj);
  } else {
    // siirretään löydetty jonon vikaksi
    state.prefs.routing.searches.splice(wIndex, 1);
    state.prefs.routing.searches.push(obj);
  }
};

export const prefsSaveRoutingSearch = (state, obj) => {
  state.prefs.routing.saved.push(obj);
};

export const prefsRemoveRoutingSearch = (state, { ts, saved }) => {
  console.log("prefsRemoveRoutingSearch");
  console.log(ts, saved);
  if (saved) {
    const indx = state.prefs.routing.saved.findIndex((elem) => elem.ts === ts);
    console.log(indx);
    console.log(state.prefs.routing.saved);
    state.prefs.routing.saved.splice(indx, 1);
    console.log(state.prefs.routing.saved);
  } else {
    const indx = state.prefs.routing.searches.findIndex(
      (elem) => elem.ts === ts
    );
    state.prefs.routing.searches.splice(indx, 1);
  }
};

export const prefsRoutingAddFavPlace = (state, obj) => {
  console.log("prefsRoutingAddFavPlace");
  Vue.set(state.prefs.routing.favPlaces, obj.nickname, obj);
};

export const prefsRoutingRemoveFavPlace = (state, favObj) => {
  Vue.delete(state.prefs.routing.favPlaces, favObj.nickname);
};

export const prefsStopFilterLine = (state, { stopCode, lineString }) => {
  if (!(stopCode in state.prefs.fav.stopFilters)) {
    Vue.set(state.prefs.fav.stopFilters, stopCode, []);
  }
  const lineCode = stripLetters(lineString);
  if (state.prefs.fav.stopFilters[stopCode].indexOf(lineCode) === -1) {
    state.prefs.fav.stopFilters[stopCode].push(lineCode);
  } else {
    state.prefs.fav.stopFilters[stopCode] = state.prefs.fav.stopFilters[
      stopCode
    ].filter((e) => e !== lineCode);
  }
  if (state.prefs.fav.stopFilters[stopCode].length === 0) {
    Vue.delete(state.prefs.fav.stopFilters, stopCode);
  }
};

// //////////////////////////////////////////////////////
//
// Virtual stops
//

export const prefsVSNewName = (state, { key, name }) => {
  state.prefs.fav.virtualStops[key].name = name;
};

export const prefsVSToggleLineFilter = (state, { key, line }) => {
  const { filters } = state.prefs.fav.virtualStops[key];
  console.log(filters);
  if (filters.indexOf(line) === -1) {
    filters.push(line);
  } else {
    Vue.set(
      state.prefs.fav.virtualStops[key],
      "filters",
      filters.filter((l) => l !== line)
    );
  }
};

export const prefsVSAddOrRemoveStopCode = (state, { key, code }) => {
  const { stopCodes } = state.prefs.fav.virtualStops[key];
  if (stopCodes.indexOf(code) === -1) {
    stopCodes.push(code);
  } else {
    Vue.set(
      state.prefs.fav.virtualStops[key],
      "stopCodes",
      stopCodes.filter((l) => l !== code)
    );
  }
};

export const prefsVSAddNew = (state, name) => {
  const newKey = `${Object.keys(state.prefs.fav.virtualStops).length + 1}`;
  Vue.set(state.prefs.fav.virtualStops, newKey, {
    name,
    filtersOn: false,
    stopCodes: [],
    filters: [],
  });
  state.prefs.fav.stopOrder = [{ normal: false, code: newKey }].concat(
    state.prefs.fav.stopOrder
  );
};

export const prefsVSRemove = (state, key) => {
  Vue.delete(state.prefs.fav.virtualStops, key);
  state.prefs.fav.stopOrder = state.prefs.fav.stopOrder.filter(
    (e) => e.normal === true || (e.normal === false && e.code !== key)
  );
};

export const checkStopConsistency = (state) => {
  if (window.ALL_STOPS_BY_CODE) {
    const keys = Object.keys(window.ALL_STOPS_BY_CODE);
    const notExistingStops = state.prefs.fav.stop.filter(
      (stopCode) => keys.indexOf(stopCode) === -1
    );
    if (notExistingStops.length > 0) {
      console.log(`NOT EXISTING STOPS: ${notExistingStops}`);

      notExistingStops.forEach((stopCode) => {
        // poistetaan suosikeista
        // console.log('EXIST: removing ' + stopCode)
        prefsFavoriteChange(state, { id: stopCode, type: "stop" });

        // poistetaan virtuaaleista, jos löytyy jostakin
        Object.keys(state.prefs.fav.virtualStops).forEach((vsKey) => {
          if (
            state.prefs.fav.virtualStops[vsKey].stopCodes.indexOf(stopCode) !==
            -1
          ) {
            // console.log('EXIST: removing from ' + vsKey)
            prefsVSAddOrRemoveStopCode(state, { key: vsKey, code: stopCode });
          }
        });
      });
    }
  }
};
