export const km = (val) => {
  const int = parseInt(val);
  const str = `${int / 1000}`;
  return str.substr(0, 4);
};

export const min = (val) => {
  return val / 60;
};

export const colon = (val) => {
  return `${val.substr(0, 2)}:${val.substr(2)}`;
};

export const stripTime = (val) => {
  return val.substring(8, 12);
};
