import store from "./index";
import { mapAddBus, updateLocation, mapRemoveBus } from "./busActions";

let startedBusses = false;
let startedStops = false;
let onloadBussesAdded = false;
let onloadStopsAdded = false;

const animStopRoutine = () => {
  const addOneStop = () => {
    const addStopsLen = animObject.addStopsList.length;
    if (addStopsLen > 0) {
      const stopMarker = animObject.addStopsList.pop();
      if (
        stopMarker &&
        !(stopMarker.options.code in store.state.live.STOPS_ADDED)
      ) {
        store.state.stopLayer.addLayer(stopMarker);
        store.state.live.STOPS_ADDED[stopMarker.options.code] = true;
      }
      if (animObject.addStopsList.length === 0 && startedStops) {
        onloadStopsAdded = true;
      }
      return true;
    }
    return false;
  };

  const stopStartTime = new Date();
  while (addOneStop()) {
    const now = new Date();
    if (onloadStopsAdded && now - stopStartTime > 10) {
      break;
    }
  }
};

const animBusRoutine = () => {
  const addOne = () => {
    const newBussesLen = animObject.addBussesList.length;
    if (newBussesLen > 0) {
      const id = animObject.addBussesList.pop();
      mapAddBus(id, store.state.live.latestBlob.lines[id]);
      if (animObject.addBussesList.length === 0 && startedBusses) {
        onloadBussesAdded = true;
        // console.log('BURSTS::: bus done')
      }
      return true;
    }
    return false;
  };

  const busStartTime = new Date();
  let budgetUsed = false;
  while (addOne()) {
    const now = new Date();
    if (onloadBussesAdded && now - busStartTime > 10) {
      budgetUsed = true;
      break;
    }
  }

  if (!budgetUsed) {
    const updateBussesLen = animObject.updateBussesList.length;
    if (updateBussesLen > 0) {
      const id = animObject.updateBussesList.pop();
      updateLocation(
        store.state.live.BUS_MARKERS[id],
        store.state.live.latestBlob.lines[id]
      );
    }

    const removeBussesLen = animObject.removeBussesList.length;
    if (removeBussesLen > 0) {
      const id = animObject.removeBussesList.pop();
      mapRemoveBus(id);
    }
  }
};

function animLoop() {
  requestAnimationFrame(() => {
    animBusRoutine();
    animStopRoutine();
    requestAnimationFrame(animLoop);
  });
}

export const animObject = {
  odd: 0,
  addBussesList: [],
  addBusses(tklIds) {
    this.addBussesList = [...new Set(this.addBussesList.concat(tklIds))];
    if (!startedBusses) startedBusses = true;
  },
  updateBussesList: [],
  updateBusses(tklIds) {
    if (this.odd === 0) {
      this.updateBussesList = [
        ...new Set(this.updateBussesList.concat(tklIds)),
      ];
      this.odd = 1;
    } else {
      this.updateBussesList = [
        ...new Set(tklIds.concat(this.updateBussesList)),
      ];
      this.odd = 0;
    }
  },
  removeBussesList: [],
  removeBusses(tklIds) {
    this.removeBussesList = this.removeBussesList.concat(tklIds);
  },
  addStopsList: [],
  addStops(elems) {
    if (this.addStopsList.length > 0) {
      console.log(`DROPPING STOPS FROM QUEUE: ${this.addStopsList.length}`);
    }
    // this drops all stops that were scheduled to be added but not yet added
    // --> they're now oustide of the viewport so why bother
    this.addStopsList = elems;
    if (!startedStops) startedStops = true;
  },
};

export const animService = animObject;

export function initLoop() {
  console.log("anim::initLoop");
  animLoop();
}
