import { TrackJS } from "trackjs";

import {
  graphOnwardStops,
  graphOnwardStopsGhost,
  graphSTOPWaltti,
  graphSTOPTimetableWaltti,
} from "../fetch";
import { dateToDateStr } from "../utils";

const poll = (commit, stopCode) => {
  graphSTOPWaltti(stopCode, false)
    .then((data) => {
      commit("stopData", data.data.stop);
    })
    .catch((err) => {
      TrackJS.track(err);
    });
};

export const staticStop = (
  { commit },
  { stopCode, str = dateToDateStr(new Date()) }
) => {
  graphSTOPTimetableWaltti(stopCode, str)
    .then((data) => {
      commit("stopTimetableData", { dateStr: str, data: data.data.stop });
    })
    .catch((err) => {
      TrackJS.track(err);
    });
};

export const pollFavStops = ({ commit, getters, state }) => {
  const r = () => {
    getters.pollableStops.forEach((stopCode) => {
      poll(commit, stopCode);
    });
    if (state.ROUTER.mobile) {
      setTimeout(r, 5000);
    }
  };
  // ensin staattiset tiedot
  // getters.pollableStops.forEach((stopCode) => {
  //   staticStop(commit, stopCode);
  // });
  // sitten livetietojen pollaus
  r();
};

// hakee pysäkin staattiset ja reaaliaikaiset tiedot
// jos pysäkki on näkyvillä, jatkaa pollausta 10 s välein
// kutsujat: searcher::clicked ja stopActions::clicked
export const pollSingleStop = ({ commit, state }, stopCode) => {
  const r = () => {
    poll(commit, stopCode);
    if (
      state.ROUTER.visible &&
      state.ROUTER.corner === "stop" &&
      state.stop.showStop === stopCode
    ) {
      setTimeout(r, 5000);
    }
  };
  staticStop({ commit }, { stopCode });
  r();
};

// ///////////////////////////////////////////////////////////////
//
//
//
//  bussien pollaukset: reitit ja seuraavat pysäkit
//
//
//

const busDataRequest = (tklId, ghostRef) => {
  let req;
  if (ghostRef) {
    req = graphOnwardStopsGhost(ghostRef);
  } else {
    req = graphOnwardStops(tklId);
  }

  return req.then((res) => {
    if (ghostRef) {
      return { tklId, ghost: true, ...res.data.vehicleGhost };
    }
    return { tklId, ...res.data.vehicle };
  });
};

// tätä voidaan kutsua mistä tahansa (käytännössä busClicked + visibilityChange)
// --> kysyy bussin seuraavat pysäkit 10 sekunnin välein perustuen state.bus.showBus
export const pollBus = ({ commit, state }) => {
  if (!state.bus.showBus) return;
  const tklId = state.bus.showBus;

  let ghostRef;
  if (state.live.latestBlob.lines[tklId].ghost) {
    ghostRef = state.live.latestBlob.lines[tklId].ghostRef;
  }

  const r = () => {
    busDataRequest(tklId, ghostRef).then((data) => {
      commit("busPanelData", data);

      if (
        state.ROUTER.visible &&
        state.ROUTER.corner === "bus" &&
        state.bus.showBus === tklId
      ) {
        setTimeout(r, 10000);
      }
    });
  };
  r();
};

export const pollBusRoute = ({ dispatch, commit, state }) => {
  if (!state.bus.showBusRoute) return;

  const tklId = state.bus.showBusRoute;
  const { ghost } = state.live.latestBlob.lines[tklId];

  let ghostRef;
  if (ghost) {
    ghostRef = state.live.latestBlob.lines[tklId].ghostRef;
  }

  const r = () => {
    // setTimeoutin vuoksi tätä saatetaan kutsua vaikka reitti jo piilotettu
    busDataRequest(tklId, ghostRef).then((data) => {
      commit("busPanelData", data);
      dispatch("addBusRouteMarkers", { tklId, stopArr: data.stops });

      setTimeout(() => {
        if (state.ROUTER.visible && state.bus.showBusRoute === tklId) {
          r();
        }
      }, 15000);
    });
  };

  r();
};
