<template>
  <button
    type="button"
    class="close"
    :class="classes"
    :style="styles"
    aria-label="Close"
  >
    <span aria-hidden="true">&times;</span>
  </button>
</template>
<script>
export default {
  name: "Close",
  props: {
    classes: {
      type: String,
      required: false,
      default: "",
    },
    styles: {
      type: String,
      required: false,
      default: "",
    },
  },
};
</script>
