import store from "./store/index";

const graphFetch = (queryObj) =>
  fetch(store.state.URLS.APIROOT, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(queryObj),
  })
    .then((res) => res.json())
    .then((data) => data);

const WALTTI_KEY = "72828c80449a47f19cfa7e9ee6fad503";
const walttiFetch = (queryObj) =>
  fetch("https://api.digitransit.fi/routing/v1/routers/waltti/index/graphql", {
    method: "POST",
    headers: {
      "Content-Type": "application/graphql",
      Accept: "application/json",
      "digitransit-subscription-key": WALTTI_KEY,
    },
    body: queryObj,
  })
    .then((res) => res.json())
    .then((data) => data);

export function fetchBlob() {
  return new Promise((resolve, reject) => {
    fetch(`${store.state.URLS.APIROOT}/blob`, {
      method: "GET",
      // mode: 'no-cors',
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    })
      .then((res) => res.json())
      .then((data) => resolve(data))
      .catch((err) => reject(err));
  });
}

export function graphSTOPLines(strID) {
  const query = {
    query: `query {
              stop(code: "${strID}") {
                ts
                name
                code
                lines
              }}`,
  };
  return graphFetch(query);
}

export function graphSTOPWaltti(id) {
  const query = `{
        stop(id: "tampere:${id}" ) {
          name
          code
          routes {
            shortName
          }
          stoptimesWithoutPatterns(numberOfDepartures: 100) {
          scheduledArrival
          realtimeArrival
          arrivalDelay
          scheduledDeparture
          realtimeDeparture
          departureDelay
          realtime
          realtimeState
          serviceDay
          headsign
          pickupType
          trip {
            id
            routeShortName
          }
        }
      }
    }`;
  return walttiFetch(query);
}

export function graphSTOPTimetableWaltti(id, dateStr) {
  const query = `{
    stop(id: "tampere:${id}") {
      name
      code
      routes {
        shortName
      }
      stoptimesForServiceDate(date: "${dateStr}") {
        pattern {
          headsign
          code
          route {
            id
            shortName
            longName
            mode
            agency {
              id
              name
            }
          }
          id
        }
        stoptimes {
          realtimeState
          scheduledDeparture
          serviceDay
          headsign
          pickupType
        }
      }
    }
  }`;
  return walttiFetch(query);
}

export function graphSTOP(strID, fast) {
  const query = {
    query: `query {
              stop(code: "${strID}", fast: ${fast}) {
                ts
                name
                code
                lines
                ${fast ? "timeTableString" : ""}
                departures {
                  code
                  name
                  monitored
                  time
                  delay
                }
              }}`,
  };
  return graphFetch(query);
}

export function graphOnwardStops(tklId) {
  const query = {
    query: `query {
              vehicle(id: "${tklId}") {
                ts
                stops {
                  code
                  name
                  arrival
                }
              }}`,
  };
  return graphFetch(query);
}

export function graphOnwardStopsGhost(tklId) {
  const query = {
    query: `query {
              vehicleGhost(id: "${tklId}") {
                ts
                stops {
                  code
                  name
                  arrival
                }
              }}`,
  };
  return graphFetch(query);
}

export function geoFetch(queryObj) {
  return fetch(`${store.state.URLS.APIROOT}/geo`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      Accept: "application/json",
    },
    body: JSON.stringify(queryObj),
  })
    .then((r) => r.json())
    .then((d) => d);
}
