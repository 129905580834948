/* eslint-disable no-new */
import Vue from "vue";
import store from "./store/index";

let containerAdded = false;

export default () => {
  if (containerAdded) return;
  containerAdded = true;

  const container = () =>
    import(/* webpackChunkName: "container" */ "./components/container");

  new Vue({
    el: "#app",
    render: (h) => h(container),
    store,
  });
};
