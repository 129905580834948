/* globals ga */
/* eslint-disable no-empty */
import { BUNDLEMODE } from "./globalFlags";

export const trackObjClick = (type, code) => {
  try {
    ga("send", "event", {
      eventCategory: "objClick",
      eventAction: type,
      eventValue: 1,
      eventLabel:
        type === "bus"
          ? code
          : `${code} ${window.ALL_STOPS_BY_CODE[code].name}`,
    });
  } catch (e) {}
};

export const trackPanelView = (panel) => {
  console.log(`trackPanelView ${panel}`);
  try {
    ga("send", "event", {
      eventCategory: "panel",
      eventAction: panel,
      eventValue: 1,
    });
  } catch (e) {}
};

export const trackSettingsUsage = (setting) => {
  console.log(`trackSettingsUsage ${setting}`);
  try {
    ga("send", "event", {
      eventCategory: "setting",
      eventAction: setting,
      eventValue: 1,
    });
  } catch (e) {}
};

export const trackFeatureUsage = (feature) => {
  console.log(`trackFeatureUsage ${feature}`);
  try {
    ga("send", "event", {
      eventCategory: "feature",
      eventAction: feature,
      eventValue: 1,
    });
  } catch (e) {}
};

export const gaMark = (name) => {
  if (!("GA_ARRAY" in window)) {
    window.GA_ARRAY = [];
  }

  if ("performance" in window) {
    window.GA_ARRAY.push({
      n: name,
      t: window.performance.now(),
    });
  }
};

export const trackTimings = () => {
  const sent = [];
  setTimeout(() => {
    console.log("trackTimings...");
    if (window.GA_ARRAY.length !== 3) {
      console.log("all values not avail; -> scheduling...");
      trackTimings();
    }
    window.GA_ARRAY.forEach((elem) => {
      if (sent.indexOf(elem.n) === -1) {
        sent.push(elem.n);
        ga("send", {
          hitType: "timing",
          timingCategory: "UI",
          timingVar: elem.n,
          timingValue: Math.round(elem.t),
        });
      }
    });
  }, 3000);
};

export const trackFirstTimer = () => {
  console.log("trackFirstTimer");
  setTimeout(() => {
    ga("send", "event", {
      eventCategory: "visitor",
      eventAction: window.GA_VISIT_FIRST_TIME,
      eventValue: window.GA_VISIT_COUNT,
    });
  }, 3500);
};

export const msgShown = () => {
  console.log("msgShown");
  setTimeout(() => {
    ga("send", "event", {
      eventCategory: "msg",
      eventAction: "api-err",
    });
  }, 3500);
};

export const trackMsg = (id, userAction) => {
  console.log("trackMsg");
  setTimeout(() => {
    ga("send", "event", {
      eventCategory: "msg",
      eventAction: userAction,
      eventLabel: id,
    });
  }, 1500);
};

export const gaRouting = (type) => {
  setTimeout(() => {
    ga("send", "event", {
      eventCategory: "routing",
      eventAction: type,
    });
  }, 1500);
};

export const gaSW = (type) => {
  ga("send", "event", {
    eventCategory: "SW",
    eventAction: type,
    eventLabel: type,
  });
};

export const trackBundleUsage = () => {
  ga("send", "event", {
    eventCategory: "bundle",
    eventAction: BUNDLEMODE,
    eventLabel: BUNDLEMODE,
  });
};
